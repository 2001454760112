import React from "react"
import { graphql, navigate } from "gatsby"
let redirected = false

let hiddenInSafari = ["mathmusic/the-sun-will-never-rest/", "mathmusic/lectiones-elementares-mathematice/"];

export default function MathMusicPage({data,path}){
  let paths = data.allMarkdownRemark.edges.map(e => e.node.fields.path)
  if(typeof window !== "undefined" && !redirected){
    navigate("/"+paths[Math.floor(Math.random()*paths.length)])
    redirected = true
  }
  return (
      <div></div>
  )
}



export const query = graphql`
query GetMathMusicPages {
  allMarkdownRemark(filter: {fields: {table: {eq: "mathmusic"}}, frontmatter: {draft: {ne: true}}}) {
    edges {
      node {
        id
        fields {
          path
          slug
        }
      }
    }
  }
}
`
